
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93eD0_45aLJH_SWaJVw5CBvPn8cEE2xWIMXlvr23TEyf8PMMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as indexG_WfXOzFIdmY7nC2wskK9_5LGE8hGwIJyZEyCaXLOuMMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as locationsfkq2Fzxqj6jVcj0sOd_XG1zClGT3f_45rQN9xYLag6SdcMeta } from "/vercel/path0/pages/locations.vue?macro=true";
import { default as _91apartmentSlug_93TA8jQIfFRcXDDecoZs9oOBuTth_45rv0hJlpX3aBdTcOsMeta } from "/vercel/path0/pages/properties/[slug]/[apartmentSlug].vue?macro=true";
import { default as bookheS7lBHjHJ4mmegZh5jqsfph4NQUeZNIb4_gJGa0mu0Meta } from "/vercel/path0/pages/properties/[slug]/book.vue?macro=true";
import { default as index5QBr0gd3UMfQNOaVaojsHPrpZ34ZAtIT150yA7qJ8pYMeta } from "/vercel/path0/pages/properties/[slug]/index.vue?macro=true";
import { default as _91slug_93WZ1OAL9tUGV1PXTL8pOZGcX40g8ETIuxX6WHgZxRCa8Meta } from "/vercel/path0/pages/restaurants/[slug].vue?macro=true";
import { default as _91_91pageNumber_93_93L4GwvuO_cuDsVsL66gQGmHYY1Ob2LlhpzYW_45C2Tmm2AMeta } from "/vercel/path0/pages/stories/[[page]]/[[pageNumber]].vue?macro=true";
import { default as _91slug_93ukb6ytBTIEUo5a7BP7KUuAjNmhZUtdyi2FtURUJxoX8Meta } from "/vercel/path0/pages/stories/[slug].vue?macro=true";
export default [
  {
    name: "slug___nl",
    path: "/nl/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue")
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "locations___nl",
    path: "/nl/locations",
    component: () => import("/vercel/path0/pages/locations.vue")
  },
  {
    name: "locations___en",
    path: "/locations",
    component: () => import("/vercel/path0/pages/locations.vue")
  },
  {
    name: "properties-slug-apartmentSlug___nl",
    path: "/nl/properties/:slug()/:apartmentSlug()",
    component: () => import("/vercel/path0/pages/properties/[slug]/[apartmentSlug].vue")
  },
  {
    name: "properties-slug-apartmentSlug___en",
    path: "/properties/:slug()/:apartmentSlug()",
    component: () => import("/vercel/path0/pages/properties/[slug]/[apartmentSlug].vue")
  },
  {
    name: "properties-slug-book___nl",
    path: "/nl/properties/:slug()/book",
    meta: bookheS7lBHjHJ4mmegZh5jqsfph4NQUeZNIb4_gJGa0mu0Meta || {},
    component: () => import("/vercel/path0/pages/properties/[slug]/book.vue")
  },
  {
    name: "properties-slug-book___en",
    path: "/properties/:slug()/book",
    meta: bookheS7lBHjHJ4mmegZh5jqsfph4NQUeZNIb4_gJGa0mu0Meta || {},
    component: () => import("/vercel/path0/pages/properties/[slug]/book.vue")
  },
  {
    name: "properties-slug___nl",
    path: "/nl/properties/:slug()",
    component: () => import("/vercel/path0/pages/properties/[slug]/index.vue")
  },
  {
    name: "properties-slug___en",
    path: "/properties/:slug()",
    component: () => import("/vercel/path0/pages/properties/[slug]/index.vue")
  },
  {
    name: "restaurants-slug___nl",
    path: "/nl/restaurants/:slug()",
    component: () => import("/vercel/path0/pages/restaurants/[slug].vue")
  },
  {
    name: "restaurants-slug___en",
    path: "/restaurants/:slug()",
    component: () => import("/vercel/path0/pages/restaurants/[slug].vue")
  },
  {
    name: "stories-page-pageNumber___nl",
    path: "/nl/stories/:page?/:pageNumber?",
    component: () => import("/vercel/path0/pages/stories/[[page]]/[[pageNumber]].vue")
  },
  {
    name: "stories-page-pageNumber___en",
    path: "/stories/:page?/:pageNumber?",
    component: () => import("/vercel/path0/pages/stories/[[page]]/[[pageNumber]].vue")
  },
  {
    name: "stories-slug___nl",
    path: "/nl/stories/:slug()",
    component: () => import("/vercel/path0/pages/stories/[slug].vue")
  },
  {
    name: "stories-slug___en",
    path: "/stories/:slug()",
    component: () => import("/vercel/path0/pages/stories/[slug].vue")
  }
]