import revive_payload_client_9vqsYwDE8SffS_2fPKa1aO_1oAPM5V9SfbWcIS_i8BA from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@types+node@20.17.24_eslint@8.57.1_less@4.2.2_rollup@4.36.0_stylelint@14.16.1_typ_x5jamimchapbnyjqwyzesbuisy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_9KfHC6affVfSJU9zCT_FWWF2zpwxngqXoOsz5PEucZs from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@types+node@20.17.24_eslint@8.57.1_less@4.2.2_rollup@4.36.0_stylelint@14.16.1_typ_x5jamimchapbnyjqwyzesbuisy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_f3XIG_rO1fmqRaVjI07dOZ7TTBqEcVb16Sv9BU9bp_8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@types+node@20.17.24_eslint@8.57.1_less@4.2.2_rollup@4.36.0_stylelint@14.16.1_typ_x5jamimchapbnyjqwyzesbuisy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_IwzQBNCqmcFPSP0G9iDaiXveexnNsBACknwcRC_OZeo from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@types+node@20.17.24_eslint@8.57.1_less@4.2.2_rollup@4.36.0_stylelint@14.16.1_typ_x5jamimchapbnyjqwyzesbuisy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_w_d6DF_B1NQ4FRmLMlBwFnR06bj4dAvlVycmRW7QHR0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@types+node@20.17.24_eslint@8.57.1_less@4.2.2_rollup@4.36.0_stylelint@14.16.1_typ_x5jamimchapbnyjqwyzesbuisy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__sT0X_TQzEgziXh4e6Q7SAmNp77SB_0EKEgv2zUEkd4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@types+node@20.17.24_eslint@8.57.1_less@4.2.2_rollup@4.36.0_stylelint@14.16.1_typ_x5jamimchapbnyjqwyzesbuisy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_sbQVBRLoOWg3_i273Xj_JQIP3YexaIL4gjU0vQUGY0A from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@types+node@20.17.24_eslint@8.57.1_less@4.2.2_rollup@4.36.0_stylelint@14.16.1_typ_x5jamimchapbnyjqwyzesbuisy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_DXDPEuw6kESCr7Vgg_qwHLOQB29Fb41e7HfQ9Yoviv8 from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_typescript@5.8.2_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_7bb_dR0MDTzLuOfrWqr5gqgo4_9GCK0rqMFjdt6jpZM from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@types+node@20.17.24_eslint@8.57.1_less@4.2.2_rollup@4.36.0_stylelint@14.16.1_typ_x5jamimchapbnyjqwyzesbuisy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_NxV0ZklKwaBZhCMpzU3yxuqZs9h86yCX4tEd_mTjyhk from "/vercel/path0/node_modules/.pnpm/@vueuse+motion@2.2.6_vue@3.5.13/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import nuxt_plugin_OeEbp0g0XByBDCakBtFuxw6WyAyJkupPzvhVRijm8jo from "/vercel/path0/node_modules/.pnpm/nuxt-delay-hydration@1.3.8/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import switch_locale_path_ssr_Zk_O2hFfIto74t255DWq_Xi5HlU_D062264yocYIqRw from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.36.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_tY9XtwaFGxbSyoSpMpzJ2BsjwdqqQ15t2uQVy4s_tUs from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.36.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_ZGNgUmm_yQI_xhsdqCqMKBOeI_zubJvESBUZoTLQwEo from "/vercel/path0/node_modules/.pnpm/@digitalnatives+graphql-client@4.2.0_graphql@16.10.0_nuxt@3.16.0_vue@3.5.13/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.js";
import plugin_aO0iVatIge_o2SDjAH7tB9uo9NqtwIZ_C3l1gIzCS2Y from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import pwa_icons_CwENKVbP__eFaLulpJB992leyalWzGcDYst87grnCQ0 from "/vercel/path0/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_@vite-pwa+assets-generator@0.2.6_vite@5.4.14/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_x3uzxFZYJs_Bkp91AClITkjNLkqCxv2QAXd7NKIKGpc from "/vercel/path0/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_@vite-pwa+assets-generator@0.2.6_vite@5.4.14/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import gtm_w1mUd627MTflyHOzfKw9s7kvhuSkBafhA0pnEHlbGcI from "/vercel/path0/plugins/gtm.ts";
import vue_skeletor_e2uFlV9nEWmNOCJL9pT2EjkGA2aA9EYz6zAb3w5PofM from "/vercel/path0/plugins/vue-skeletor.js";
import bugsnag_uSRfs7CdgKdEwLBjDKEXN9fbf_KPta61qCfyQrzOZBM from "/vercel/path0/plugins/bugsnag.ts";
export default [
  revive_payload_client_9vqsYwDE8SffS_2fPKa1aO_1oAPM5V9SfbWcIS_i8BA,
  unhead_9KfHC6affVfSJU9zCT_FWWF2zpwxngqXoOsz5PEucZs,
  router_f3XIG_rO1fmqRaVjI07dOZ7TTBqEcVb16Sv9BU9bp_8,
  payload_client_IwzQBNCqmcFPSP0G9iDaiXveexnNsBACknwcRC_OZeo,
  navigation_repaint_client_w_d6DF_B1NQ4FRmLMlBwFnR06bj4dAvlVycmRW7QHR0,
  check_outdated_build_client__sT0X_TQzEgziXh4e6Q7SAmNp77SB_0EKEgv2zUEkd4,
  chunk_reload_client_sbQVBRLoOWg3_i273Xj_JQIP3YexaIL4gjU0vQUGY0A,
  plugin_vue3_DXDPEuw6kESCr7Vgg_qwHLOQB29Fb41e7HfQ9Yoviv8,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_7bb_dR0MDTzLuOfrWqr5gqgo4_9GCK0rqMFjdt6jpZM,
  motion_NxV0ZklKwaBZhCMpzU3yxuqZs9h86yCX4tEd_mTjyhk,
  nuxt_plugin_OeEbp0g0XByBDCakBtFuxw6WyAyJkupPzvhVRijm8jo,
  switch_locale_path_ssr_Zk_O2hFfIto74t255DWq_Xi5HlU_D062264yocYIqRw,
  i18n_tY9XtwaFGxbSyoSpMpzJ2BsjwdqqQ15t2uQVy4s_tUs,
  plugin_ZGNgUmm_yQI_xhsdqCqMKBOeI_zubJvESBUZoTLQwEo,
  plugin_aO0iVatIge_o2SDjAH7tB9uo9NqtwIZ_C3l1gIzCS2Y,
  pwa_icons_CwENKVbP__eFaLulpJB992leyalWzGcDYst87grnCQ0,
  pwa_client_x3uzxFZYJs_Bkp91AClITkjNLkqCxv2QAXd7NKIKGpc,
  gtm_w1mUd627MTflyHOzfKw9s7kvhuSkBafhA0pnEHlbGcI,
  vue_skeletor_e2uFlV9nEWmNOCJL9pT2EjkGA2aA9EYz6zAb3w5PofM,
  bugsnag_uSRfs7CdgKdEwLBjDKEXN9fbf_KPta61qCfyQrzOZBM
]