import routerOptions0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@types+node@20.17.24_eslint@8.57.1_less@4.2.2_rollup@4.36.0_stylelint@14.16.1_typ_x5jamimchapbnyjqwyzesbuisy/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/vercel/path0/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}